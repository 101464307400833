<template>
    <div>
        <div v-for="(invoice,key) in row.item.incoming_invoice_name"
             :key="key">
            <a class="mr-2"
               @click.prevent="openIncomingInvoice(invoice.id)"
               href="#">{{ invoice.id }} {{ invoice.number ? '(' + invoice.number + ')' : '' }} - {{ invoice.amount }}&euro;</a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
    methods: {
        openIncomingInvoice(id) {
            this.$root.$children[0].openModal('incoming-invoices-modal', {
                    id: id,
                }, this.refreshTable,
                {}
            )
        },
    }
}
</script>